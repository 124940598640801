import React from 'react'
import Layout from '../components/layout'

const ImpressumPage = () => (
  <Layout>
    <div id="wrapper">
      <div className="content text-only">
        <div className="inner">
          <h1>Impressum</h1>
          <p>
            <strong>Angaben gemäß § 5 TMG:</strong>
          </p>

          <p>
            Manuela Weisz
            <br />
            Personal- und Organisationsentwicklung
            <br />
            Iserdal 1b
            <br />
            22559 Hamburg
          </p>

          <p>
            Telefon: <a href="tel:00491714069677">+49 171 4069677</a>
            <br />
            E-Mail: <a href="office@manuelaweisz.de">office@manuelaweisz.de</a>
          </p>

          <p></p>
          <p>
            Umsatzsteuer-Identifikationsnummer: <span>DE290395043</span>
          </p>

          <p>
            <strong>Hinweise zur Website</strong>
          </p>

          <ul>
            <li>
              Umsetzung:{' '}
              <a href="https://digitalnoise.de/" title="Symfony TYPO3 Hamburg">
                digitalnoise Gmbh - Agentur für moderne Softwareentwicklung
              </a>
            </li>
            <li>
              Fotos:{' '}
              <a href="https://unsplash.com/s/photos">
                https://unsplash.com/s/photos
              </a>
            </li>
            <li>
              Design: <a href="https://html5up.net">HTML5 UP</a>.<br />
            </li>
            <li>
              Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
            </li>
          </ul>

          <p>
            <strong>Haftungsausschluss:</strong>
            <br />
            <br />
            <strong>Haftung für Inhalte</strong>
            <br />
            <br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            <br />
            <br />
            <strong>Haftung für Links</strong>
            <br />
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>

          <h3>
            <strong>Urheberrecht</strong>
          </h3>

          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>

          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>

          <p>
            Das Impressum wurde mit dem{' '}
            <a
              href="https://www.activemind.de/datenschutz/impressums-generator/"
              target="_blank"
              rel="noreferrer"
            >
              Impressums-Generator der activeMind AG
            </a>{' '}
            erstellt.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default ImpressumPage
